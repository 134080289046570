import { fetchAuthSession } from "aws-amplify/auth";
import { createRouter, createWebHistory } from "vue-router";
import { useAuth } from "../composables/auth";
import { initBaleLinkAPIClient } from "../composables/balelinkApi";
import { initFieldImportAPIClient } from "../composables/fieldApi";
import {
	initUmcAPIClient,
	initUmcUnauthAPIClient,
} from "../composables/umcLambdaApi";

const { cachedAccessToken, cachedIdToken, currentUserEmail } = useAuth();

const router = createRouter({
	history: createWebHistory(),
	routes: [
		{
			path: "/",
			name: "home",
			component: () => import("../pages/Home.vue"),
		},
		{
			path: "/login",
			name: "login",
			component: () => import("../pages/Login.vue"),
		},
	],
});

router.beforeEach(async to => {
	try {
		const session = await fetchAuthSession();
		cachedAccessToken.value =
			session.tokens?.accessToken.toString() ?? null;
		cachedIdToken.value = session.tokens?.idToken?.toString() ?? null;
		if (!cachedAccessToken.value || !cachedIdToken.value) {
			throw new Error("Invalid auth tokens");
		}

		currentUserEmail.value =
			session.tokens?.idToken?.payload.email?.toString() ?? undefined;
		initBaleLinkAPIClient(cachedIdToken.value);
		initFieldImportAPIClient(cachedAccessToken.value);
		initUmcAPIClient(cachedIdToken.value);
		initUmcUnauthAPIClient();
	} catch (e: unknown) {
		if (to.name !== "login") {
			return {
				name: "login",
				query: { redirect: to.name?.toString() },
			};
		}
	}
});

router.onError((error, to) => {
	if (
		error.message.includes("Failed to fetch dynamically imported module") ||
		error.message.includes("Importing a module script failed")
	) {
		window.location = to.fullPath as string & Location;
	}
});

export default router;
