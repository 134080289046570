/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
	accountID?: string | null;
	name?: string | null;
	email?: string | null;
	/** @format date-time */
	updateTime?: string;
}

export interface AddBalerRequestBody {
	vinString?: string | null;
	name?: string | null;
}

export interface ApplyPromoSubsRequestBody {
	/** @format date-time */
	startDate?: string | null;
	/** @format int32 */
	months?: number;
}

export interface CognitoSuccessEmptyResponse {
	message?: string | null;
}

export interface CreateOwner {
	email?: string | null;
	organizationName?: string | null;
	password?: string | null;
}

export interface CreateUser {
	username?: string | null;
	name?: string | null;
	groupName?: string | null;
	accountOwner?: string | null;
	accountOwnerEmail?: string | null;
}

export interface ErrorResponseBody {
	error?: string | null;
	reference?: string | null;
}

export interface MachineInfo {
	vinString?: string | null;
	accountID?: string | null;
	/** @format int32 */
	vinId?: number;
	name?: string | null;
	/** @format date-time */
	acmSubExpiryDate?: string | null;
	isActive?: boolean;
	/** @format date-time */
	baleLinkSubExpiryDate?: string | null;
}

export interface Subscription {
	/** @format int32 */
	subscriptionID?: number;
	accountID?: string | null;
	/** @format int32 */
	vinId?: number;
	/** @format int32 */
	year?: number;
	/** @format int32 */
	month?: number;
	status?: SubscriptionStatus;
	/** @format date-time */
	creationTime?: string;
	/** @format date-time */
	updateTime?: string;
	transferrable?: boolean;
}

export enum SubscriptionStatus {
	Expired = "Expired",
	Deleted = "Deleted",
	Pending = "Pending",
	Active = "Active",
}

export interface UpdateUser {
	username?: string | null;
	email?: string | null;
	name?: string | null;
}

export interface User {
	username?: string | null;
	email?: string | null;
	name?: string | null;
	organizationId?: string | null;
	userStatus?: string | null;
}

export interface UserListResponse {
	paginationToken?: string | null;
	users?: User[] | null;
}

export interface WalletBalance {
	/** @format int32 */
	unusedCredits?: number;
	/** @format int32 */
	appliedCredits?: number | null;
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
	/** set parameter to `true` for call `securityWorker` for this request */
	secure?: boolean;
	/** request path */
	path: string;
	/** content type of request body */
	type?: ContentType;
	/** query params */
	query?: QueryParamsType;
	/** format of response (i.e. response.json() -> format: "json") */
	format?: ResponseFormat;
	/** request body */
	body?: unknown;
	/** base url */
	baseUrl?: string;
	/** request cancellation token */
	cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
	baseUrl?: string;
	baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
	securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
	customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends Response {
	data: D;
	error: E;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
	Json = "application/json",
	FormData = "multipart/form-data",
	UrlEncoded = "application/x-www-form-urlencoded",
	Text = "text/plain",
}

export class HttpClient<SecurityDataType = unknown> {
	public baseUrl: string = "";
	private securityData: SecurityDataType | null = null;
	private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
	private abortControllers = new Map<CancelToken, AbortController>();
	private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

	private baseApiParams: RequestParams = {
		credentials: "same-origin",
		headers: {},
		redirect: "follow",
		referrerPolicy: "no-referrer",
	};

	constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
		Object.assign(this, apiConfig);
	}

	public setSecurityData = (data: SecurityDataType | null) => {
		this.securityData = data;
	};

	protected encodeQueryParam(key: string, value: any) {
		const encodedKey = encodeURIComponent(key);
		return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
	}

	protected addQueryParam(query: QueryParamsType, key: string) {
		return this.encodeQueryParam(key, query[key]);
	}

	protected addArrayQueryParam(query: QueryParamsType, key: string) {
		const value = query[key];
		return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
	}

	protected toQueryString(rawQuery?: QueryParamsType): string {
		const query = rawQuery || {};
		const keys = Object.keys(query).filter(key => "undefined" !== typeof query[key]);
		return keys
			.map(key => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
			.join("&");
	}

	protected addQueryParams(rawQuery?: QueryParamsType): string {
		const queryString = this.toQueryString(rawQuery);
		return queryString ? `?${queryString}` : "";
	}

	private contentFormatters: Record<ContentType, (input: any) => any> = {
		[ContentType.Json]: (input: any) =>
			input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
		[ContentType.Text]: (input: any) => (input !== null && typeof input !== "string" ? JSON.stringify(input) : input),
		[ContentType.FormData]: (input: any) =>
			Object.keys(input || {}).reduce((formData, key) => {
				const property = input[key];
				formData.append(
					key,
					property instanceof Blob
						? property
						: typeof property === "object" && property !== null
						? JSON.stringify(property)
						: `${property}`,
				);
				return formData;
			}, new FormData()),
		[ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
	};

	protected mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
		return {
			...this.baseApiParams,
			...params1,
			...(params2 || {}),
			headers: {
				...(this.baseApiParams.headers || {}),
				...(params1.headers || {}),
				...((params2 && params2.headers) || {}),
			},
		};
	}

	protected createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
		if (this.abortControllers.has(cancelToken)) {
			const abortController = this.abortControllers.get(cancelToken);
			if (abortController) {
				return abortController.signal;
			}
			return void 0;
		}

		const abortController = new AbortController();
		this.abortControllers.set(cancelToken, abortController);
		return abortController.signal;
	};

	public abortRequest = (cancelToken: CancelToken) => {
		const abortController = this.abortControllers.get(cancelToken);

		if (abortController) {
			abortController.abort();
			this.abortControllers.delete(cancelToken);
		}
	};

	public request = async <T = any, E = any>({
		body,
		secure,
		path,
		type,
		query,
		format,
		baseUrl,
		cancelToken,
		...params
	}: FullRequestParams): Promise<HttpResponse<T, E>> => {
		const secureParams =
			((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
				this.securityWorker &&
				(await this.securityWorker(this.securityData))) ||
			{};
		const requestParams = this.mergeRequestParams(params, secureParams);
		const queryString = query && this.toQueryString(query);
		const payloadFormatter = this.contentFormatters[type || ContentType.Json];
		const responseFormat = format || requestParams.format;

		return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
			...requestParams,
			headers: {
				...(requestParams.headers || {}),
				...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
			},
			signal: (cancelToken ? this.createAbortSignal(cancelToken) : requestParams.signal) || null,
			body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
		}).then(async response => {
			const r = response as HttpResponse<T, E>;
			r.data = null as unknown as T;
			r.error = null as unknown as E;

			const data = !responseFormat
				? r
				: await response[responseFormat]()
						.then(data => {
							if (r.ok) {
								r.data = data;
							} else {
								r.error = data;
							}
							return r;
						})
						.catch(e => {
							r.error = e;
							return r;
						});

			if (cancelToken) {
				this.abortControllers.delete(cancelToken);
			}

			if (!response.ok) throw data;
			return data;
		});
	};
}

/**
 * @title S3UmcLambda
 * @version 1.0
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
	authorizedEmails = {
		/**
		 * No description
		 *
		 * @tags AuthorizedEmails
		 * @name GetAuthorizedEmails
		 * @request GET:/authorized-emails
		 */
		getAuthorizedEmails: (params: RequestParams = {}) =>
			this.request<string[], ErrorResponseBody>({
				path: `/authorized-emails`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AuthorizedEmails
		 * @name AddAuthorizedEmail
		 * @request POST:/authorized-emails/{email}
		 */
		addAuthorizedEmail: (email: string, params: RequestParams = {}) =>
			this.request<void, ErrorResponseBody>({
				path: `/authorized-emails/${email}`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AuthorizedEmails
		 * @name DeleteAuthorizedEmail
		 * @request DELETE:/authorized-emails/{email}
		 */
		deleteAuthorizedEmail: (email: string, params: RequestParams = {}) =>
			this.request<void, ErrorResponseBody>({
				path: `/authorized-emails/${email}`,
				method: "DELETE",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags AuthorizedEmails
		 * @name UpdateAuthorizedEmail
		 * @request PUT:/authorized-emails/{oldEmail}
		 */
		updateAuthorizedEmail: (
			oldEmail: string,
			query?: {
				newEmail?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<void, ErrorResponseBody>({
				path: `/authorized-emails/${oldEmail}`,
				method: "PUT",
				query: query,
				...params,
			}),
	};
	machines = {
		/**
		 * No description
		 *
		 * @tags Balers
		 * @name GetBaler
		 * @request GET:/machines/{vinString}
		 */
		getBaler: (vinString: string, params: RequestParams = {}) =>
			this.request<MachineInfo, ErrorResponseBody>({
				path: `/machines/${vinString}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Balers
		 * @name DeleteBaler
		 * @request DELETE:/machines/{vinString}
		 */
		deleteBaler: (vinString: string, params: RequestParams = {}) =>
			this.request<MachineInfo, ErrorResponseBody>({
				path: `/machines/${vinString}`,
				method: "DELETE",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Balers
		 * @name AddBaler
		 * @request POST:/machines
		 */
		addBaler: (data: AddBalerRequestBody, params: RequestParams = {}) =>
			this.request<MachineInfo, ErrorResponseBody>({
				path: `/machines`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Balers
		 * @name GetBalers
		 * @request GET:/machines
		 */
		getBalers: (params: RequestParams = {}) =>
			this.request<MachineInfo, ErrorResponseBody>({
				path: `/machines`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Balers
		 * @name RenameBaler
		 * @request PUT:/machines/rename
		 */
		renameBaler: (
			query?: {
				/** @format int32 */
				vinId?: number;
				name?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<boolean, ErrorResponseBody>({
				path: `/machines/rename`,
				method: "PUT",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Balers
		 * @name ValidateBalerOwnership
		 * @request GET:/machines/validate
		 */
		validateBalerOwnership: (
			query?: {
				balerVIN?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<boolean, ErrorResponseBody>({
				path: `/machines/validate`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),
	};
	stripe = {
		/**
		 * No description
		 *
		 * @tags Billing
		 * @name Checkout
		 * @request POST:/stripe/checkout
		 */
		checkout: (
			query?: {
				priceId?: string;
				/** @format int64 */
				quantity?: number;
				couponCode?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<string, any>({
				path: `/stripe/checkout`,
				method: "POST",
				query: query,
				format: "json",
				...params,
			}),
	};
	public = {
		/**
		 * No description
		 *
		 * @tags Billing
		 * @name StripeWebhookCreate
		 * @request POST:/public/stripe/webhook
		 */
		stripeWebhookCreate: (params: RequestParams = {}) =>
			this.request<void, any>({
				path: `/public/stripe/webhook`,
				method: "POST",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name RegisterAccountsOwner
		 * @request POST:/public/user/register
		 */
		registerAccountsOwner: (data: CreateOwner, params: RequestParams = {}) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/public/user/register`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	subscriptions = {
		/**
		 * No description
		 *
		 * @tags Subscription
		 * @name GetSubscriptionsForBaler
		 * @request GET:/subscriptions/{vinId}
		 */
		getSubscriptionsForBaler: (vinId: number, params: RequestParams = {}) =>
			this.request<Subscription[], ErrorResponseBody>({
				path: `/subscriptions/${vinId}`,
				method: "GET",
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subscription
		 * @name ApplySubscriptions
		 * @request POST:/subscriptions/{vinId}
		 */
		applySubscriptions: (vinId: number, data: string[], params: RequestParams = {}) =>
			this.request<string, ErrorResponseBody>({
				path: `/subscriptions/${vinId}`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Subscription
		 * @name ApplyPromoSubscriptions
		 * @request POST:/subscriptions/promo/{vinId}
		 */
		applyPromoSubscriptions: (vinId: number, data: ApplyPromoSubsRequestBody, params: RequestParams = {}) =>
			this.request<string, ErrorResponseBody>({
				path: `/subscriptions/promo/${vinId}`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),
	};
	wallet = {
		/**
		 * No description
		 *
		 * @tags Subscription
		 * @name GetWalletBalance
		 * @request GET:/wallet
		 */
		getWalletBalance: (params: RequestParams = {}) =>
			this.request<WalletBalance, ErrorResponseBody>({
				path: `/wallet`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
	user = {
		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUser
		 * @request GET:/user
		 */
		getUser: (
			query?: {
				userName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<User, ErrorResponseBody>({
				path: `/user`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name CreateUser
		 * @request POST:/user
		 */
		createUser: (data: CreateUser, params: RequestParams = {}) =>
			this.request<User, ErrorResponseBody>({
				path: `/user`,
				method: "POST",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name UpdateUser
		 * @request PUT:/user
		 */
		updateUser: (data: UpdateUser, params: RequestParams = {}) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/user`,
				method: "PUT",
				body: data,
				type: ContentType.Json,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name DeleteUser
		 * @request DELETE:/user
		 */
		deleteUser: (
			query?: {
				userName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/user`,
				method: "DELETE",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name EnableUser
		 * @request PUT:/user/enable
		 */
		enableUser: (
			query?: {
				userName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/user/enable`,
				method: "PUT",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name DisableUser
		 * @request PUT:/user/disable
		 */
		disableUser: (
			query?: {
				userName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/user/disable`,
				method: "PUT",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name ReInviteUser
		 * @request PUT:/user/reinvite
		 */
		reInviteUser: (
			query?: {
				userName?: string;
			},
			params: RequestParams = {},
		) =>
			this.request<User, ErrorResponseBody>({
				path: `/user/reinvite`,
				method: "PUT",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name CloseAccount
		 * @request DELETE:/user/close-account
		 */
		closeAccount: (params: RequestParams = {}) =>
			this.request<CognitoSuccessEmptyResponse, ErrorResponseBody>({
				path: `/user/close-account`,
				method: "DELETE",
				format: "json",
				...params,
			}),
	};
	users = {
		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetSubaccounts
		 * @request GET:/users
		 */
		getSubaccounts: (
			query?: {
				attributesToGet?: string[];
				paginationToken?: string;
				/**
				 * @format int32
				 * @default 60
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<UserListResponse, ErrorResponseBody>({
				path: `/users`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetUsersInGroup
		 * @request GET:/users/group
		 */
		getUsersInGroup: (
			query?: {
				groupName?: string;
				/** @default "" */
				nextToken?: string;
				/**
				 * @format int32
				 * @default 60
				 */
				limit?: number;
			},
			params: RequestParams = {},
		) =>
			this.request<UserListResponse, ErrorResponseBody>({
				path: `/users/group`,
				method: "GET",
				query: query,
				format: "json",
				...params,
			}),

		/**
		 * No description
		 *
		 * @tags Users
		 * @name GetAllOrganizations
		 * @request GET:/users/organization
		 */
		getAllOrganizations: (params: RequestParams = {}) =>
			this.request<Account[], ErrorResponseBody>({
				path: `/users/organization`,
				method: "GET",
				format: "json",
				...params,
			}),
	};
}
